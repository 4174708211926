/** Town: Car **/

import React from "react";
import { gsap } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages, scaleFullscreen } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data, preloadData;
const MOVE_FACTOR = 40;

class TownPage3 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[3];
    preloadData = globalState.assets[4];

    //refs
    this.bgVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
    currentCuePoint: 0,
    styles: {
      top: 0,
      left: 0
    }
  }


  componentDidMount = () => {

    setTimeout(() => {
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.text1Ref, "right", "bottom", globalState.textPosition.rightBottom);


    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });

    //add base track
    globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);
    globalState.baseAudioRef.updateVolume(2, 75);

    //Update dimensions
    this.updateDimensions();



    //Add resize listener
    window.addEventListener("resize", this.updateDimensions);

    this.reqAnimFrame = window.requestAnimationFrame(this.update);

    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1, 
      globalState.cdnUrl + preloadData.text2, 
      globalState.cdnUrl + preloadData.bgVideoPoster], 
      this.preloadCallback);

  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }



  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);

    //remove base track
    //globalState.baseAudioRef.updateTrack(1, false);

    window.cancelAnimationFrame(this.reqAnimFrame);

    //remove event listeners
    window.removeEventListener("resize", this.updateDimensions);
  }

  bgVideoEnded = () => {
    console.log("Bg Video ended");
    //this.bgVideoRef.seekVideo(30);
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "right", "bottom", globalState.textPosition.rightBottom);


    } else {
      //hide text
      hideText(this.refs.text1Ref, "right");
    }
  }

  isTransitioning = () => {
    this.bgVideoRef.pauseVideo();
  }

  updateDimensions = () => {
    console.log('updateDimensions');
    scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1184, 666);

  }

  cuePointCallback = (num) =>{
    
    if(this.state.currentCuePoint !== num){
      this.setState({currentCuePoint: num});
      console.log("update cuepoint: " + num);
      gsap.to(this.refs["hc"+num], 0.5, { alpha:1 } );
			gsap.to(this.refs["hc"+num], 0.5, { alpha:0, delay:3 } );
    }
  }

  update = (e) => {

    //var t:Point = new Point();
    let t = {
      x: 0,
      y: 0
    }

    t.x += Math.random() * -MOVE_FACTOR;
    t.y += Math.random() * -MOVE_FACTOR;
    let newTop = this.state.styles.top;
    let newLeft = this.state.styles.left;

    newTop -= (newTop - t.x) * 0.05;
    newLeft -= (newLeft - t.y) * 0.05;

    this.setState({
      styles: {
        top: newTop,
        left: newLeft
      }
    })


    this.reqAnimFrame = window.requestAnimationFrame(this.update);
  }


  render() {

    return (

      <>

        <link rel="prefetch" href="/town/4" />
        {preloadData && (
        <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideoLoop} />
        )}

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/town/4" prevLocation="/town/2"
          isTransitioning={this.isTransitioning} />

        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Town" />

          <div className="text-wrapper">
            <div className="text text--right-bottom" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "12vw", marginBottom: "15vh" }} onClick={this.toggleTextDisplay} alt="I DECIDED TO VISIT PINE POINT AGAIN. On my way up, I stopped in Yellowknife, to see what had changed in my old hometown. Turns out, not as much as you’d think. The houses I had lived in were all still there, pretty much unchanged. More channels, furniture you can take apart. Though the families offered, I didn’t feel any need to go inside." />
            </div>
          </div>

          {data.bgVideo ?
            <FullscreenVideo file={globalState.videoUrl + data.bgVideo}
              poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} loop={true} ended={this.bgVideoEnded} ref={div => this.bgVideoRef = div} fullscreenClass={'fullwidth-top-video'} cuePoints={[4, 8, 17]} cuePointCallback={this.cuePointCallback} globalState={globalState}/> : ''
          }

          <div className="fullscreen-bg town-bg" ref="fullscreenBgRef" >
            <img className="car-overlay" style={this.state.styles} ref={div => this.carOverlayRef = div} src={globalState.cdnUrl + data.carOverlay} alt=""/>
          </div>
          
          <div className="house-captions">
            <img ref="hc1" className="house-caption" src={globalState.cdnUrl + data.tape1} alt="House #1" />
            <img ref="hc2" className="house-caption" src={globalState.cdnUrl + data.tape2} alt="House #2" />
            <img ref="hc3" className="house-caption" src={globalState.cdnUrl + data.tape3} alt="House #3" />
          </div>


        </div>
        )}
      </>
    );
  }


};

export default TownPage3
